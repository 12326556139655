import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";

const faqs = [
  {
    question: "How does Ujourney track user journeys?",
    answer:
      "Ujourney uses advanced tracking technology to monitor user interactions across all marketing channels, providing accurate attribution and insights for iOS apps.",
  },
  {
    question: "Is Ujourney compliant with iOS privacy requirements?",
    answer:
      "Yes, Ujourney is fully compliant with iOS privacy guidelines and App Tracking Transparency (ATT) framework.",
  },
  {
    question: "How quickly can I see the results?",
    answer:
      "Our real-time reporting system provides instant insights, allowing you to make data-driven decisions immediately.",
  },
  {
    question: "What makes Ujourney different from other tracking solutions?",
    answer:
      "Ujourney combines cross-channel tracking, real-time reporting, and AI-powered insights with deferred deep linking support, providing a comprehensive solution for iOS advertisers.",
  },
];

const FAQ = () => {
  return (
    <section className="py-12 bg-white" id="faq">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
        </div>
        <Accordion.Root type="single" collapsible className="space-y-4">
          {faqs.map((faq, index) => (
            <Accordion.Item
              key={index}
              value={`item-${index}`}
              className="border rounded-lg overflow-hidden"
            >
              <Accordion.Trigger className="flex justify-between w-full px-4 py-4 text-left bg-white hover:bg-gray-50">
                <span className="text-lg font-medium">{faq.question}</span>
                <ChevronDown className="h-5 w-5 transition-transform duration-200" />
              </Accordion.Trigger>
              <Accordion.Content className="px-4 py-3 bg-gray-50">
                <p className="text-gray-600">{faq.answer}</p>
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      </div>
    </section>
  );
};

export default FAQ;
