import React from "react";

const reasons = [
  {
    title: "Drive Higher ROAS",
    description:
      "Maximize your return on ad spend with precise tracking and optimization",
    icon: "💰",
  },
  {
    title: "Full Channel Coverage",
    description:
      "Track and analyze user journeys across all marketing channels",
    icon: "🌐",
  },
  {
    title: "Deferred Deep Link Support",
    description: "Provide seamless user experiences with smart deep linking",
    icon: "🔗",
  },
];

const WhyUjourney = () => {
  return (
    <section className="py-12 bg-gray-50" id="why">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Why Choose Ujourney?
          </h2>
        </div>
        <div className="mt-10">
          <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
            {reasons.map((reason) => (
              <div
                key={reason.title}
                className="relative bg-white p-6 rounded-lg shadow-md"
              >
                <div className="text-4xl mb-4">{reason.icon}</div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  {reason.title}
                </h3>
                <p className="text-gray-500">{reason.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUjourney;
