import React from "react";
import { motion } from "framer-motion";
import { ArrowRight, BarChart2, Zap, Shield } from "lucide-react";
import heroImage from "../assets/images/hero_image_min.png";

const Hero = () => {
  return (
    <div className="relative overflow-hidden bg-gradient-to-br from-blue-600 via-blue-500 to-indigo-600">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-t from-blue-600/50 to-transparent" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-32">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text Content */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="text-left space-y-8"
          >
            <h1 className="text-5xl md:text-7xl font-bold text-white tracking-tight">
              2x ROAS
              <span className="block text-blue-200">for Your iOS Apps</span>
            </h1>

            <p className="max-w-2xl text-xl text-blue-100 leading-relaxed">
              Make Every User Count, Make Every Dollar Work
            </p>

            <div className="flex flex-col sm:flex-row gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-white text-blue-600 rounded-full font-medium 
                           hover:bg-blue-50 transition-colors flex items-center justify-center"
              >
                Join Waitlist
                <ArrowRight className="ml-2 h-5 w-5" />
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 border-2 border-white text-white rounded-full 
                           font-medium hover:bg-white/10 transition-colors"
              >
                Contact Us Now
              </motion.button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
              {[
                { icon: <BarChart2 />, text: "50% Lower CPI" },
                { icon: <Zap />, text: "Real-time Insights" },
                { icon: <Shield />, text: "Privacy First" },
              ].map((item, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 * i }}
                  className="flex items-center space-x-3 text-white"
                >
                  {item.icon}
                  <span className="font-medium">{item.text}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Right Column - Hero Image */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="relative"
          >
            <div className="relative z-10">
              <img
                src={heroImage}
                alt="Analytics Dashboard"
                className="w-full h-auto rounded-2xl shadow-2xl"
                style={{
                  filter: "drop-shadow(0 25px 25px rgb(0 0 0 / 0.15))",
                }}
              />
              {/* Decorative elements */}
              <div className="absolute -z-10 -inset-4 bg-gradient-to-r from-blue-400 to-indigo-400 opacity-20 blur-2xl rounded-3xl" />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
