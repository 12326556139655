import React from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
// import { cn } from "../lib/utils";
import { ChevronDown, Menu, X } from "lucide-react";
import { Link } from "react-router-dom";

const navigationItems = [
  {
    title: "Features",
    href: "#features",
    description: "Explore our powerful features for iOS app tracking",
  },
  {
    title: "Results",
    href: "#how-it-works",
    description: "See how we help businesses boost their ROI",
  },
  {
    title: "Why Ujourney",
    href: "#why",
    description: "Discover what makes us different",
  },
  {
    title: "FAQ",
    href: "#faq",
    description: "Get answers to common questions",
  },
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <nav className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-16 items-center">
        <div className="mr-4 flex items-center">
          <span className="text-2xl font-bold text-primary">Ujourney</span>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex lg:flex-1">
          <NavigationMenu.Root>
            <NavigationMenu.List className="flex flex-1 list-none items-center justify-center space-x-1">
              {navigationItems.map((item) => (
                <NavigationMenu.Item key={item.title}>
                  <NavigationMenu.Trigger className="group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50">
                    {item.title}
                    <ChevronDown
                      className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                      aria-hidden="true"
                    />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="absolute top-full w-[200px] rounded-lg border bg-popover p-2 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95">
                    <div className="w-full p-2">
                      <a
                        href={item.href}
                        className="block rounded-md p-3 hover:bg-muted focus:outline-none"
                      >
                        <div className="text-sm font-medium leading-none">
                          {item.title}
                        </div>
                        <p className="mt-1 text-sm text-muted-foreground">
                          {item.description}
                        </p>
                      </a>
                    </div>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              ))}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>

        {/* CTA Button */}
        <div className="hidden lg:flex lg:items-center lg:justify-end lg:flex-1">
          <Link to="/signin">
            <span className="text-primary pr-8">Sign In</span>
          </Link>
          <Link to="/signup">
            <button className="inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
              Create Account
            </button>
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="flex lg:hidden flex-1 justify-end">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="inline-flex items-center justify-center rounded-md p-2 text-primary hover:bg-accent hover:text-accent-foreground"
          >
            <span className="sr-only">Open main menu</span>
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-16 left-0 right-0 bg-background border-b lg:hidden">
          <div className="container space-y-1 py-3">
            {navigationItems.map((item) => (
              <a
                key={item.title}
                href={item.href}
                className="block rounded-md px-3 py-2 text-base font-medium text-foreground hover:bg-accent hover:text-accent-foreground"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.title}
              </a>
            ))}
            <div className="pt-2 px-3">
              <Link to="/signin">
                <button className="w-full rounded-md bg-white border border-primary px-4 py-2 text-sm font-medium text-primary transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                  Sign In
                </button>
              </Link>
              <Link to="/signup">
                <button className="mt-3 w-full rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                  Create Account
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
